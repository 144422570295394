import styled, { keyframes } from 'styled-components';
import useTranslation from 'shared/hooks/useTranslation';
import Trans from 'next-translate/Trans';
import Carousel from 'react-multi-carousel';
import { ArrowBtnLeftV2, ArrowBtnRightV2 } from 'shared/components/CarouselArrows';
import { useInView, animated } from '@react-spring/web';
import { PARTNERS } from 'shared/utils/partnersLogos';
import PartnersLogo from './PartnersLogo';

type ListProps = {
  activeIndex?: boolean;
};

const CarouselWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  margin: 0 auto;
  height: 100%;

  div {
    height: 100%;
    padding-bottom: 7rem;
  }

  ${({ theme }) => theme.MEDIA.XL} {
    display: none;
  }
`;

const Section = styled.section`
  align-items: center;
  background: url('/img/home/square-pattern.svg') no-repeat;
  background-position: 50% 100%;
  background-size: contain;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  max-width: 160rem;
  margin: 0 auto;
  position: relative;
  font-family: ${({ theme }) => theme.FONTS.MAIN};

  ${({ theme }) => theme.MEDIA.XL} {
    padding-bottom: 16rem;
    margin-bottom: 8rem;
  }
`;

const gradient = keyframes`
  {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem 2.4rem 4rem 2.4rem;

  ${({ theme }) => theme.MEDIA.MD} {
    padding: 8rem 2.4rem 6.8rem 2.4rem;
  }

  ${({ theme }) => theme.MEDIA.XL} {
    padding: 12rem 3.4rem 8.4rem 3.4rem;
  }
`;

const AnimatedGradientHeader = styled(animated.h2)`
  animation: ${gradient} 5s ease-in-out infinite;
  background: linear-gradient(263.37deg, #fccc51 0.21%, #fd6e40 52.03%, #e75b95 103.85%);
  background-size: 150%;
  font-size: 3.6rem;
  font-weight: 600;
  font-family: inherit;
  line-height: 4.2rem;
  text-align: center;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${({ theme }) => theme.MEDIA.XL} {
    font-size: 8.4rem;
    line-height: 8.3rem;
    line-height: 120%;
  }
`;

const AnimatedSubHeader = styled(animated.span)`
  color: black;
  font-family: inherit;
  font-size: 3.6rem;
  font-weight: 500;
  line-height: 4.5rem;
  margin-top: 1.2rem;
  font-size: 2.8rem;
  line-height: 4.2rem;
  text-align: center;

  ${({ theme }) => theme.MEDIA.XL} {
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4.5rem;
  }
`;

const PartnersList = styled.ul<ListProps>`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-items: center;
  height: 100%;
  gap: 2.4rem;
  max-width: 160rem;
  margin: 0 auto;
  padding: 0.8rem 0.4rem;

  img {
    filter: grayscale(1);
  }

  ${({ theme }) => theme.MEDIA.MD} {
    gap: 3.2rem;
    padding: 0 2rem;
  }

  ${({ theme }) => theme.MEDIA.XL} {
    gap: 3.2rem;
    padding: 0 4rem;
    min-width: 60rem;
  }
`;

const AnimationWrapper = styled(animated.div)`
  overflow-x: auto;
  width: 100%;
  display: none;

  ${({ theme }) => theme.MEDIA.XL} {
    display: block;
  }
`;

function LogoSection(): JSX.Element {
  const { t } = useTranslation('homePage');
  const LOGOS_SET = [
    [0, 2],
    [2, 4],
    [4, 6],
    [6, 8],
    [8, 10],
    [10, 12],
  ];
  const [header, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
      config: {
        duration: 800,
      },
    }),
    {
      rootMargin: '-20% 0%',
      once: true,
    },
  );

  const [subHeader, springs2] = useInView(
    () => ({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
      delay: 800,
      config: {
        duration: 800,
      },
    }),
    {
      rootMargin: '-20% 0%',
      once: true,
    },
  );

  const [listRef, spring3] = useInView(
    () => ({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
      delay: 0,
      config: {
        duration: 800,
      },
    }),
    {
      rootMargin: '-20% 0%',
    },
  );

  const responsive = {
    mobile: {
      breakpoint: { min: 0, max: 768 },
      items: 1,
    },
    tablet: {
      breakpoint: { min: 769, max: 3229 },
      items: 3,
    },
  };

  return (
    <Section>
      <HeaderWrapper>
        <AnimatedGradientHeader ref={header} style={springs}>
          {t('logoSection.jointTrustMate')}
        </AnimatedGradientHeader>
        <AnimatedSubHeader ref={subHeader} style={springs2}>
          <Trans
            i18nKey="homePage:logoSection.manyCompanies"
            values={{
              companiesCount: 9000,
            }}
            fallback="homePageEN:logoSection.manyCompanies"
          />
        </AnimatedSubHeader>
      </HeaderWrapper>
      <AnimationWrapper ref={listRef} style={spring3}>
        <PartnersList>
          {PARTNERS.map(({ name, size: { width, height } }) => (
            <PartnersLogo key={name} name={name} size={{ width, height }} />
          ))}
        </PartnersList>
      </AnimationWrapper>
      <CarouselWrapper>
        <Carousel
          customRightArrow={<ArrowBtnRightV2 align="right" />}
          customLeftArrow={<ArrowBtnLeftV2 align="right" />}
          responsive={responsive}
          containerClass="carousel-container"
          showDots={false}
          ssr
          autoPlay
          infinite
          swipeable
        >
          {LOGOS_SET.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <PartnersList key={i}>
              {PARTNERS.slice(item[0], item[1]).map(({ name, size: { width, height } }) => (
                <PartnersLogo key={name} name={name} size={{ width, height }} />
              ))}
            </PartnersList>
          ))}
        </Carousel>
      </CarouselWrapper>
    </Section>
  );
}

export default LogoSection;
