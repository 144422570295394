/* eslint-disable import/prefer-default-export */
export const PARTNERS = [
  {
    name: 'modivo',
    size: {
      height: 53,
      width: 200,
    },
  },
  {
    name: 'victorias-secret',
    size: {
      width: 475,
      height: 41,
    },
  },
  {
    name: 'neonail',
    size: {
      width: 253,
      height: 44,
    },
  },
  {
    name: 'rylko',
    size: {
      width: 189,
      height: 39,
    },
  },
  {
    name: '4f',
    size: {
      width: 107,
      height: 44,
    },
  },
  {
    name: 'martes',
    size: {
      width: 243,
      height: 44,
    },
  },
  {
    name: 'kross',
    size: {
      width: 311,
      height: 56,
    },
  },
  {
    name: 'new-balance',
    size: {
      width: 103,
      height: 57,
    },
  },
  {
    name: 'bechcicki',
    size: {
      width: 251,
      height: 56,
    },
  },
  {
    name: 'eobuwie',
    size: {
      width: 358,
      height: 73,
    },
  },
  {
    name: 'super-pharm',
    size: {
      width: 344,
      height: 32,
    },
  },
  {
    name: 'sinsay',
    size: {
      width: 163,
      height: 60,
    },
  },
  {
    name: 'botland',
    size: {
      width: 266,
      height: 45,
    },
  },
  {
    name: 'fitanu',
    size: {
      width: 414,
      height: 40,
    },
  },
  {
    name: 'north',
    size: {
      width: 184,
      height: 50,
    },
  },
  {
    name: 'witchen',
    size: {
      width: 347,
      height: 40,
    },
  },
  {
    name: 'gomez',
    size: {
      width: 221,
      height: 48,
    },
  },
  {
    name: 's-biegacza',
    size: {
      width: 84,
      height: 72,
    },
  },
  {
    name: 'jd',
    size: {
      width: 72,
      height: 72,
    },
  },
  {
    name: 'ws',
    size: {
      width: 139,
      height: 72,
    },
  },
  {
    name: '50-style',
    size: {
      width: 73,
      height: 72,
    },
  },
  {
    name: 'timberland',
    size: {
      width: 76,
      height: 72,
    },
  },
  {
    name: 'decathlon',
    size: {
      width: 242,
      height: 60,
    },
  },
  {
    name: 'r-gol',
    size: {
      width: 338,
      height: 42,
    },
  },
  {
    name: 'frisco',
    size: {
      width: 196,
      height: 60,
    },
  },
  {
    name: 'strefa-tenisa',
    size: {
      width: 127,
      height: 50,
    },
  },
  {
    name: 'sizeer',
    size: {
      width: 175,
      height: 49,
    },
  },
];
